import React from 'react';
import SEO from '../components/seo';
import Header from '../components/header'
import Container from '../components/Container'
import Layout from '../components/layout'
import Hero from '../components/Hero'

const About = () => (
    <Layout>
        <SEO title='About' /> 
        <Header />
        <Hero title="About Seedtribe jobs." />
        <Container as="main" id='main-content'>
            Content
        </Container>
    </Layout>
)

export default About;